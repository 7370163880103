import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import CoursePage from '../../containers/CoursePage';

const CourseTemplate = ({ data, location, pageContext }) => {
  const values = data.contentfulCoursesContentSale;
  return (
    <CoursePage
      pageContext={pageContext}
      location={location}
      description={values.description}
      title={values.title}
      data={values}
    />
  );
};

export const courseQuery = graphql`
  query($slug: String!) {
    contentfulCoursesContentSale(slug: { eq: $slug }) {
      title
      description
      contentTitle
      videoLink
      videoDescription
      segmentLinkAction
      segmentLinkDescription
      segmentDescription
      slug
      logo {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      contentSession1 {
        childContentfulRichText {
          html
        }
      }
      imageSession1 {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      imageLinkSession1
      contentSession2 {
        childContentfulRichText {
          html
        }
      }
      imageSession2 {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      imageLinkSession2
      contentSession3 {
        childContentfulRichText {
          html
        }
      }
      imageSession3 {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      imageLinkSession3
      contentSession4 {
        childContentfulRichText {
          html
        }
      }
    }
  }
`;

CourseTemplate.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.shape({
    contentfulCoursesContentSale: PropTypes.shape({
      title: PropTypes.string.isRequired,
      logoTitle: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      contentSession1: PropTypes.string.isRequired,
      imageSession1: PropTypes.object.isRequired,
      imageLinkSession1: PropTypes.string.isRequired,
      contentSession2: PropTypes.string.isRequired,
      imageSession2: PropTypes.object.isRequired,
      imageLinkSession2: PropTypes.string.isRequired,
      contentSession3: PropTypes.string.isRequired,
      imageSession3: PropTypes.object.isRequired,
      imageLinkSession3: PropTypes.string.isRequired,
      contentSession4: PropTypes.string.isRequired,
      videoLink: PropTypes.string,
      videoDescription: PropTypes.string,
      segmentDescription: PropTypes.string,
      segmentLinkAction: PropTypes.string,
      segmentLinkDescription: PropTypes.string,
      logo: PropTypes.object.isRequired,
    }),
  }).isRequired,
};

export default CourseTemplate;
