import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../../components/seo';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import PageHeader from '../../components/pageheader';
import CourseInfo from '../../components/CourseInfo';
import VideoPlayer from '../../components/VideoPlayer';
import CourseLinkButton from '../../components/CourseLinkButton';
import { Content } from './styles';
import Heading from '../../components/ui/Heading';

const CoursePage = ({ pageContext, location, title, description, data }) => {
  const {
    logo,
    logoTitle,
    contentTitle,
    videoLink,
    videoDescription,
    segmentDescription,
    segmentLinkAction,
    segmentLinkDescription,
    contentSession1,
    imageSession1,
    imageLinkSession1,
    contentSession2,
    imageSession2,
    imageLinkSession2,
    contentSession3,
    imageSession3,
    imageLinkSession3,
    contentSession4,
  } = data;

  return (
    <>
      <SEO title={title} description={description} image={logo.fluid.src} />
      <Header />
      <PageHeader pageContext={pageContext} location={location} title={title} />
      <Content>
        <Heading as="h3" textalign="center" color="primary">
          {contentTitle}
        </Heading>
        <CourseInfo
          image={logo.fluid}
          imageTitle={logoTitle}
          contentSession1={contentSession1.childContentfulRichText.html}
          imageSession1={imageSession1}
          imageLinkSession1={imageLinkSession1}
          contentSession2={contentSession2.childContentfulRichText.html}
          imageSession2={imageSession2}
          imageLinkSession2={imageLinkSession2}
          contentSession3={contentSession3.childContentfulRichText.html}
          imageSession3={imageSession3}
          imageLinkSession3={imageLinkSession3}
          contentSession4={contentSession4.childContentfulRichText.html}
        />
        {videoLink ? <VideoPlayer url={videoLink} description={videoDescription} /> : null}
        {segmentDescription ? (
          <CourseLinkButton
            path={segmentLinkAction}
            description={segmentDescription}
            buttonDescription={segmentLinkDescription}
          />
        ) : null}
      </Content>
      <Footer />
    </>
  );
};

CoursePage.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    logoTitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    videoLink: PropTypes.string,
    videoDescription: PropTypes.string,
    segmentDescription: PropTypes.string,
    segmentLinkAction: PropTypes.string,
    segmentLinkDescription: PropTypes.string,
    logo: PropTypes.object.isRequired,
    contentTitle: PropTypes.string.isRequired,
    contentSession1: PropTypes.string.isRequired,
    imageSession1: PropTypes.object.isRequired,
    imageLinkSession1: PropTypes.string.isRequired,
    contentSession2: PropTypes.string.isRequired,
    imageSession2: PropTypes.object.isRequired,
    imageLinkSession2: PropTypes.string.isRequired,
    contentSession3: PropTypes.string.isRequired,
    imageSession3: PropTypes.object.isRequired,
    imageLinkSession3: PropTypes.string.isRequired,
    contentSession4: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string,
};

CoursePage.defaultProps = {
  description: undefined,
};

export default CoursePage;
