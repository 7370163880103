import React from 'react';
import PropTypes from 'prop-types';
import Button from '../ui/button';
import Text from '../ui/Text';

const CourseLinkButton = ({ path, description, buttonDescription }) => {
  const regex = /^http/;
  const URI = regex.test(path) ? path : `/${path}`;
  return (
    <>
      {description ? (
        <Text fontSize="lg" mb="24px" align="justify">
          {description}
        </Text>
      ) : null}
      <Button to={URI} target="_blank" fullwidth>
        {buttonDescription}
      </Button>
    </>
  );
};

CourseLinkButton.propTypes = {
  path: PropTypes.string.isRequired,
  buttonDescription: PropTypes.string,
  description: PropTypes.string,
};

CourseLinkButton.defaultProps = {
  description: null,
  buttonDescription: 'Quero me preparar',
};

export default CourseLinkButton;
