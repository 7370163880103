import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Img from 'gatsby-image';
import { Container, Description, ImageContainer, ContentContainer } from './styles';
import { Row, Col } from '../ui/Wrapper';
import Box from '../box-large-image/layout-three';

const CourseInfo = data => {
  const {
    image,
    imageTitle,
    contentSession1,
    imageSession1,
    imageLinkSession1,
    contentSession2,
    imageSession2,
    imageLinkSession2,
    contentSession3,
    imageSession3,
    imageLinkSession3,
    contentSession4,
  } = data;

  return (
    <>
      <Container>
        <ContentContainer>
          <ImageContainer>
            <Img fluid={image} alt={imageTitle} title={imageTitle} />
          </ImageContainer>
          <Description>{parse(contentSession1)}</Description>
          <Row>
            <Col lg={12} md={12} className="box-item">
              <Box imageSrc={imageSession1} path={imageLinkSession1} />
            </Col>
          </Row>
          <Description>{parse(contentSession2)}</Description>
          <Row>
            <Col lg={12} md={12} className="box-item">
              <Box imageSrc={imageSession2} path={imageLinkSession2} />
            </Col>
          </Row>
          <Description>{parse(contentSession3)}</Description>
          <Row>
            <Col lg={12} md={12} className="box-item">
              <Box imageSrc={imageSession3} path={imageLinkSession3} />
            </Col>
          </Row>
          <Description>{parse(contentSession4)}</Description>
        </ContentContainer>
      </Container>
    </>
  );
};

CourseInfo.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object.isRequired,
    imageTitle: PropTypes.string.isRequired,
    contentSession1: PropTypes.string.isRequired,
    imageSession1: PropTypes.object.isRequired,
    imageLinkSession1: PropTypes.string.isRequired,
    contentSession2: PropTypes.string.isRequired,
    imageSession2: PropTypes.object.isRequired,
    imageLinkSession2: PropTypes.string.isRequired,
    contentSession3: PropTypes.string.isRequired,
    imageSession3: PropTypes.object.isRequired,
    imageLinkSession3: PropTypes.string.isRequired,
    contentSession4: PropTypes.string.isRequired,
  }).isRequired,
};

export default CourseInfo;
